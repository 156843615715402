import { useState, type KeyboardEvent, type ReactNode, useEffect } from "react";
import { Box, IconFa } from "@cruk/cruk-react-components";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";
import { useActiveSectionContext } from "@fwa/src/contexts/ActiveSectionContext";
import { useKey } from "@fwa/src/hooks/useKey";
import { camelToSnakeCase } from "@fwa/src/utils/formatUtils";

import { FormActions } from "@fwa/src/components/FormActions";
import { Highlight } from "@fwa/src/components/Highlight";

import {
  EditableWrapper,
  EditIconWrapper,
} from "@fwa/src/components/Editable/styles";
import { type PageType } from "@fwa/src/types";

type Props = {
  /**
   * fieldName is used mainly for populating the aria-label and the GTM update events
   * so if this is working with form with more than one field then use a sensible name for this groups of fields
   */
  fieldName?: string;
  /**
   * viewNode is the non-editable view of the field
   */
  viewNode: ReactNode;
  /**
   * editNode is the editable view of the field
   */
  editNode: ReactNode;
  /**
   * handleSubmit: on submit clicked, MUST something truthy or the editable modal will not close
   */
  handleSubmit: () => Promise<PageType | boolean | void>;
  /**
   * handleCancel: on cancel clicked, will always close modal
   */
  handleCancel?: () => void;
  /**
   * isLoading: loading state, will disable sumbit button
   */
  isLoading: boolean;
  /**
   * editButtonColor: edit icon colour
   */
  editButtonColor?: string;
  /**
   * editButtonBackgroundColor: edit icon background colour
   */
  editButtonBackgroundColor?: string;
  /**
   * editButtonBackgroundColor: edit icon background colour
   */
  editButtonHidden?: boolean;
  /**
   * editButtonPosition: edit icon position
   */
  editButtonPosition?: "top-right" | "center";
  /**
   * tooltip is on hover tooltip
   */
  tooltip?: string;
  /**
   * fullWidth: Used for fields like header image when the field is full width in parent container and has no padding
   */
  fullWidth?: boolean;
  /**
   * half opacity on hover
   */
  fadeOnHover?: boolean;
};

/**
 * This component is for inline editing fields where you can pass in a viewNode and an editNote.
 * It turns turn the viewNote into one big edit button which opens the editNode.
 * The editNode is wrapped modal style form with submit/cancel keyboard actions
 * */
export const Editable2 = ({
  fieldName,
  viewNode,
  editNode,
  handleSubmit,
  handleCancel,
  isLoading,
  tooltip,
  editButtonColor = "inherit",
  editButtonHidden,
  editButtonBackgroundColor,
  editButtonPosition = "top-right",
  fullWidth = false,
  fadeOnHover = true,
}: Props) => {
  const { sendTrackingEvent } = useTrackingContext();
  const [activeSectionContext, setActiveSectionContext] =
    useActiveSectionContext();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    const isActiveSection =
      !!fieldName && activeSectionContext.activeSection === fieldName;
    setIsEditing(isActiveSection);
  }, [activeSectionContext]);
  // Global escape key listener
  useKey(
    () => {
      doCancel();
    },
    {
      detectKeys: ["Escape"],
    },
    [],
  );

  const closeEnhancePageHighlight = () => {
    if (activeSectionContext.activeSection) {
      setActiveSectionContext({ activeSection: null });
    }
  };

  const doSubmit = () => {
    handleSubmit()
      .then((resData) => {
        // We don't want to close the modal until we know we got something back from to prove a successful API call,
        // This doesn't have to be page data it could be anything.
        // This is because we catching all errors to display error messages in the form before this point
        if (!resData) return undefined;
        if (fieldName) {
          sendTrackingEvent({
            event: `update_${camelToSnakeCase(fieldName || "")}`,
          });
        }
        setIsEditing(false);
        closeEnhancePageHighlight();
        return undefined;
      })
      .catch(() => {
        return undefined;
      });
  };

  const handleEditClicked = () => {
    setIsEditing(true);
  };

  const handleEditClickedKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 13 || e.key === "Enter") {
      e.preventDefault();
      setIsEditing(true);
    }
  };

  const doCancel = () => {
    if (handleCancel) handleCancel();
    setIsEditing(false);
    closeEnhancePageHighlight();
  };

  return isEditing ? (
    <Highlight isHighlighted fullWidth={fullWidth}>
      <Box
        padding="s"
        role="dialog"
        aria-label={`edit ${fieldName || ""} modal`}
      >
        {editNode}
        <FormActions
          isValid={true}
          isLoading={isLoading}
          onCancel={doCancel}
          onSubmit={doSubmit}
          submitButtonType="button"
        />
      </Box>
    </Highlight>
  ) : (
    <EditableWrapper
      role="button"
      title={tooltip || "edit"}
      aria-label={
        tooltip?.toLocaleLowerCase() ||
        `edit${fieldName ? ` ${fieldName}` : ""}`
      }
      onClick={handleEditClicked}
      onKeyDown={handleEditClickedKeyDown}
      tabIndex={0}
      $fadeOnHover={fadeOnHover}
      $editButtonPosition={editButtonPosition}
    >
      {viewNode}
      {editButtonHidden ? null : (
        <EditIconWrapper
          $editButtonBackgroundColor={editButtonBackgroundColor}
          $editButtonPosition={editButtonPosition}
          $editButtonColor={editButtonColor}
        >
          <IconFa faIcon={faPenToSquare} />
        </EditIconWrapper>
      )}
    </EditableWrapper>
  );
};

export default Editable2;
